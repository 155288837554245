'use client';

import { useAccountStore } from '@/lib/client/store/account.store';
import { ReactNode, useEffect } from 'react';

export function AccountProvider({ children }: { children: ReactNode }) {
  const { setAccount } = useAccountStore();

  useEffect(() => {
    const accountStr = localStorage.getItem('jr_account');

    if (accountStr) {
      try {
        const account = JSON.parse(accountStr);
        if ('type' in account && 'hash' in account) {
          setAccount({ ...account, initialized: true });
        }
      } catch (err: any) {}
    }
  }, []);

  return <>{children}</>;
}
