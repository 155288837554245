'use client';

import Link from 'next/link';
import { Logo } from '@/components/icons/logo';
import { LinkButton } from '@/components/ui/link-button';
import { useParams, usePathname } from 'next/navigation';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useUser } from '@/modules/user/user.queries';
import { useAccountStore } from '@/lib/client/store/account.store';

import dynamic from 'next/dynamic';
import { LocaleSelector } from './locale-selector';
import { useTranslations } from 'next-intl';

const BusinessAppHeader = dynamic(
  () => import('@/components/BusinessAppHeader'),
  { loading: () => <Header /> }
);
const CandidateAppHeader = dynamic(
  () => import('@/components/CandidateAppHeader'),
  { loading: () => <Header /> }
);

export function PublicHeader() {
  const params = useParams();
  const pathname = usePathname();
  const { data: user } = useUser();
  const { type: accountType } = useAccountStore();

  const hide =
    (pathname.startsWith('/b') &&
      !!params['business_slug'] &&
      !!params['job_slug']) ||
    (pathname.startsWith('/jobs') && !!params['jobHash']);

  if (hide) {
    return null;
  }

  if (!!user && accountType === 'business') {
    return <BusinessAppHeader />;
  }

  if (!!user && accountType === 'candidate') {
    return <CandidateAppHeader />;
  }

  return <Header />;
}

function Header() {
  const t = useTranslations('components.publicHeader');
  const { data: user } = useUser();

  return (
    <header className='sticky top-0 inset-x-0 z-50 border-b bg-background'>
      <nav className='container h-20 flex items-center justify-between isolate'>
        <div className='flex items-center gap-4'>
          <Link
            href='https://jobreel.io'
            target='_blank'
            rel='noreferrer noopener'
            className='rounded-full focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1'
          >
            <Logo className='w-28 sm:w-32' />
          </Link>

          <div className='hidden sm:flex items-center gap-4 text-sm font-semibold text-zinc-800 transition-colors'>
            <Link
              href='https://jobreel.io/job-seekers'
              target='_blank'
              rel='noreferrer noopener'
              className='hover:text-foreground'
            >
              {t('forJobSeekers')}
            </Link>

            <Link
              href='https://jobreel.io'
              target='_blank'
              rel='noreferrer noopener'
              className='hover:text-foreground'
            >
              {t('forBusinesses')}
            </Link>

            <Link
              href='https://jobreel.io/mission'
              target='_blank'
              rel='noreferrer noopener'
              className='hover:text-foreground'
            >
              {t('mission')}
            </Link>
          </div>
        </div>

        <div className='hidden sm:flex items-center gap-2'>
          <LocaleSelector />
          <LinkButton
            href={!!user ? '/app' : '/auth/sign-in'}
            variant='ghost'
            size='sm'
            className='group text-sm font-semibold'
          >
            {t('signIn')}
          </LinkButton>
          <LinkButton href='/jobs' variant='brand'>
            {t('seeJobs')}
          </LinkButton>
        </div>

        <div className='sm:hidden'>
          <DropdownMenu modal>
            <DropdownMenuTrigger asChild>
              <Button
                size='icon'
                variant='ghost'
                className='group relative flex flex-col gap-2'
              >
                <div className='w-5 h-0.5 group-data-[state=open]:rotate-45 group-data-[state=open]:translate-y-[5px] transition-transform duration-300 ease-out will-change-transform bg-foreground' />
                <div className='w-5 h-0.5 group-data-[state=open]:-rotate-45 group-data-[state=open]:-translate-y-[5px] transition-transform duration-300 ease-out will-change-transform bg-foreground' />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className='sm:hidden w-screen translate-y-4 flex flex-col'>
              <LinkButton
                href='/jobs'
                variant='ghost'
                className='justify-start rounded-full'
              >
                {t('forJobSeekers')}
              </LinkButton>
              <LinkButton
                href='https://jobreel.io'
                target='_blank'
                rel='noreferrer noopener'
                variant='ghost'
                className='justify-start rounded-full'
              >
                {t('forBusinesses')}
              </LinkButton>
              <LinkButton
                href='https://jobreel.io/mission'
                target='_blank'
                rel='noreferrer noopener'
                variant='ghost'
                className='justify-start rounded-full'
              >
                {t('mission')}
              </LinkButton>
              <LinkButton
                href={!!user ? '/app' : '/auth/sign-in'}
                variant='ghost'
                className='rounded-full'
              >
                {t('signIn')}
              </LinkButton>
              <LinkButton href='/jobs' variant='brand'>
                {t('seeJobs')}
              </LinkButton>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </nav>
    </header>
  );
}

// function AddJobButton({ className }: { className?: string }) {
//   const upsertJobButtonRef = useRef<HTMLButtonElement | null>(null);
//   const { data: user, isLoading: isUserLoding } = useUser();
//   const { data: businesses, isLoading: isBusinessesLoading } =
//     useListBusinessesForUser();
//   const { type: accountType } = useAccountStore();

//   const isLoading = isUserLoding || isBusinessesLoading;

//   if (isLoading) {
//     return (
//       <Button variant='brand' loading={true}>
//         Add Job
//       </Button>
//     );
//   }

//   if (!user) {
//     return (
//       <LinkButton
//         href='/auth/sign-in?as=business'
//         variant='brand'
//         className={className}
//       >
//         Add Job
//       </LinkButton>
//     );
//   }

//   if (accountType === 'business') {
//     return (
//       <UpsertJobButton
//         ref={upsertJobButtonRef}
//         variant='brand'
//         className={className}
//       >
//         Add Job
//       </UpsertJobButton>
//     );
//   }

//   if (accountType === 'candidate' && !!businesses?.length) {
//     // Select account modal
//     return (
//       <>
//         <UpsertJobButton ref={upsertJobButtonRef} className='hidden'>
//           Add Job
//         </UpsertJobButton>
//         <BusinessSelectModalButton
//           variant='brand'
//           className={className}
//           onSelect={() => {
//             upsertJobButtonRef.current?.click();
//           }}
//         >
//           Add Job
//         </BusinessSelectModalButton>
//       </>
//     );
//   }

//   if (accountType === 'candidate' && !businesses?.length) {
//     return (
//       <>
//         <UpsertJobButton ref={upsertJobButtonRef} className='hidden'>
//           Add Job
//         </UpsertJobButton>
//         <AddNewBusinessModalButton
//           variant='brand'
//           className={className}
//           onSuccess={async (business) => {
//             upsertJobButtonRef.current?.click();
//           }}
//         >
//           Add Job
//         </AddNewBusinessModalButton>
//       </>
//     );
//   }

//   return null;
// }
